/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/alert.css');
require('../css/app.css');
require('../css/backgroundColor.css');
require('../css/base.css');
require('../css/calendar.css');
require('../css/global_search.css');
require('../css/job_ad.css');
require('../css/recipe.collections.css');
require('../css/recipe_process_of_elimination.css');
require('../css/login.css');
require('../css/recipe.collection.css');
require('../css/checklist.css');
require('../css/create.form.css');
require('../css/flash.card.groups.css');
require('../css/flash.cards.css');
require('../css/form.css');
require('../css/incomplete.flash.cards.css');
require('../css/recipe.css');
require('../css/recipe_photo.css');
require('../css/flat.css');
require('../css/contacts.css');
require('../css/flat.advertisement.search.css');
require('../css/flat.advertisement.import.css');
require('../css/flat.advertisements.overview.css');
require('../css/main.page.css');
require('../css/product.css');
require('../css/shopping.list.css');
require('../css/store.css');
require('../css/crib.sheets.css');
require('../css/task.css');
require('../css/taskSorting.css');
require('../css/taskTag.css');
require('../css/textColor.css');
require('../css/multi_consumes.css');
require('../css/recurrent_tasks.css');
require('../css/breadcrumb.css');
require('../css/consume.css');
require('../css/watching.css');
require('../css/show.css');
require('../css/gitlab.css');
require('../css/gitlab_bookmarks.css');
require('../css/gitlab_available_issues.css');
require('../css/vendor/dropzone.min.css');
require('../css/sub_menu.css');
require('../css/color.css');
